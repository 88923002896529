import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import common_ptBr from '../src/translations/pt-BR/common.json'
import common_en from '../src/translations/en/common.json'

const userLang =
  localStorage.getItem('language') ||
  navigator.language ||
  navigator.userLanguage ||
  'en'

i18next.init({
  interpolation: { escapeValue: false },
  lng: userLang,
  resources: {
    en: {
      common: common_en
    },
    'pt-BR': {
      common: common_ptBr
    }
  }
})

i18next.on('languageChanged', language => {
  localStorage.setItem('language', language)
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
